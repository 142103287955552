@import '../common/colors';
@import '../common/fonts';

.button {

    transition: box-shadow 0.2s;
    display: inline-block;
    vertical-align: middle;
    font-family: $font-default;
    font-weight: bold;
    font-size: 16px;
    background-color: $color-buttons;
    cursor: pointer;
    text-decoration: none;
    border: 0px;
    border-style: solid;
    box-sizing: border-box;
    color: $color-text-light;
    border-radius: 3px;
    padding: 15px 35px;
    box-shadow: 0px 0px 0px rgba(235, 87, 87, 0);

    &:hover {

        box-shadow: 0px 4px 4px rgba(235, 87, 87, 0.35);
        background-color: $color-buttons-hovered;

    }

    &_circle {

        font-size: 0px;
        width: 40px;
        height: 40px;
        padding: 0px;
        border-radius: 100%;
        background-position: center;
        background-repeat: no-repeat;

    }

    &_rounded {

        border-radius: 10px;

    }

    &_back {

        background-image: url("/pictures/arrow-to-left.svg");
        background-repeat: no-repeat;
        background-position: 23px center;
        background-size: 12px 12px;

        padding-left: 23px + 12px + 23px;

    }

}
