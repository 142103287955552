$color-general: #0178ff;
$color-second: #fff;
$color-text-light: #fff;
$color-text-dark: #333;
$color-text-light-gray: #AEAEAE;
$color-buttons: #EB5757;
$color-buttons-hovered: #EB5757;
$color-card-background: #fff;
$color-gray: #dbdbdb;
$color-light-gray: #efefef;
$color-button-popup: #0477f7;
