@import "../common/fonts";
@import "../common/colors";

.article {

    width: 1030px;
    margin: 0px auto;
    overflow-x: hidden;

    &__title {

        font-family: $font-default;
        font-style: normal;
        font-weight: bold;
        line-height: 36px;
        font-size: 30px;
        color: $color-text-dark;
        margin-bottom: 20px;
        margin-top: 20px;
        word-wrap: break-word;

    }

    &__date {

        display: block;
        font-family: $font-default;
        font-style: normal;
        font-weight: normal;
        line-height: 30px;
        font-size: 18px;
        color: $color-text-light-gray;
        margin-bottom: 20px;

    }

    &__text {

        font-family: $font-default;
        font-style: normal;
        font-weight: normal;
        line-height: 32px;
        font-size: 15px;
        color: $color-text-dark;
        margin-bottom: 50px;
        word-wrap: break-word;

    }

    &__footer {

        margin-bottom: 20px;

    }

}
